@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Kanit');

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI'), url(./assets/fonts/SegoeUI.ttf) format('truetype');
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
